import React  ,{useState ,useEffect}from 'react'
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { useSelector } from "react-redux";
import { updateBankInfoAction } from '../actions/settingAction';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2'
import Loader from '../Components/Loader';
import { getBankAction } from '../actions/settingAction';

function Bank() {

  const  bankInfoReducer = useSelector(state => state. bankInfoReducer)
  const {bankInfo ,bankInfoLoading}= bankInfoReducer


    const [images, setImages] = React.useState([]);
  
    const maxNumber = 69;
    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      setImages(imageList);
  
      if(imageList.length < 1){

        setImage(bankInfo.image)
  
      }else {
  
        setImage(imageList[0].data_url)
        console.log(imageList[0].data_url);
  
      }
     
    };

  const  updateBankInfo = useSelector(state => state. updateBankInfo)
  const {bankInfoReducerUpdateLoading ,bankInfoReducerUpdate}= updateBankInfo


  const [bankCode ,setBankCode] = useState(bankInfo.code)
  const [bankname ,setBankName] = useState(bankInfo.name)
  const[image ,setImage]=useState(bankInfo.image)
  const [slogan ,setSlogan]=useState(bankInfo.slogan)
  const [mission ,setMission]=useState(bankInfo.mission)
  const [vission ,setVission]=useState(bankInfo.vission)

  const dispatch=useDispatch()


  const successMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: message ,
      showConfirmButton: false,
      timer: 1500
    });
  }


  const UpdateBankInfo=(e)=>{
    e.preventDefault();

    if( !(bankCode.match('^[0-9]{3}$'))){
            
      Swal.fire({
          icon: 'error',
          title: 'Invalid Bank/Credit Union Code:',
          text: 'Please provide valid Bank/Credit Union Code',
          footer: 'Error Please Fix This'
        })

  }else if(bankname=='') {

    Swal.fire({
      icon: 'error',
      title: 'Bank/Credit Union Name Error',
      text: 'Please provide Bank/Credit Union Name',
      footer: 'Error Please Fix This'
    })

  }else {


    try {
    
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
        confirmButton: "btn btn-success mx-4",
        cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
    });
    swalWithBootstrapButtons.fire({
        title: "Comfirm Bank Update",
        text: "Are you sure you want to update Bank information ?",
        icon: "info",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        position:"center"
     
  
    }).then((result) => {
  
        if (result.isConfirmed) {
  
            
          dispatch(updateBankInfoAction({trans_code:bankInfo.trans_code ,
            bankCode,
            bankname ,
            image ,
            slogan ,
            image ,
            mission,
            vission 
      
          }))
  
  
        successMessage('Bank Infomation Successfully Updated')

        
        
            
  
        } else if (result.dismiss === Swal.DismissReason.cancel) {
  
         // clearGrade();
        }
        
    });
  
  
    } catch (error) {
      
    }


  }

   


  }


  useEffect(()=>{

    dispatch(getBankAction(1))

  },[bankInfoReducerUpdate])



  return (
    <div>

        <h3>Bank/Credit Union Infomation</h3>

        <hr/>

        <div className='solcon overflow-auto vh-100'>


        <div className='content-r ms-5 me-5 ps-5 pe-5 mt-3 pt-3 mb-5'>

            
            <div >

                {bankInfoReducerUpdateLoading && <Loader/>}

                <div className='form-group'>
                    <label for='Bank / Credit Union code'><strong>Bank/Credit Union Code:</strong></label>
                    <input type='text' name='Bank code' required className='form-control' onChange={(e)=>setBankCode(e.target.value)} value={bankCode}/>
                </div>

                <div className='form-group mt-3'>
                    <label for='Bank / Credit Union Name'><strong>Bank/Credit Union Name:</strong></label>
                    <input type='text' name='Bank Name' required className='form-control' value={bankname} onChange={(e)=>setBankName(e.target.value)}/>
                </div>


                <div className='form-group mt-3'>
                    <label for='Bank / Credit Union Name'><strong>Bank / Credit Union Logo:</strong></label>
                </div>

                {
                   bankInfo.image==null ?  <img src="/images/profile-img.jpg" alt="Profile" className="rounded-circle "/> :  
                   <img src={ bankInfo.image} alt="Profile" className="rounded-circle logo-size"/>
                    
                    }

           
                <div className='form-group mt-3'>
               
                 <ImageUploading 
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={maxNumber}
                    dataURLKey="data_url"
                    acceptType={["jpg" ,'PNG']}
                    className='mt-4'
                >
                    {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps
                    }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                        {
                            images.length ==1 ? '' :(
                                <button className='btn-class'
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                >
                                Browse Of Logo
                                </button>
                            )

                        }
                       
                        &nbsp;
                       
                        {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                            <img src={image.data_url} alt="" width="120" />
                            <div className="image-item__btn-wrapper">
                            <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                            <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                            </div>
                        </div>
                        ))}
                    </div>
                    )}
                </ImageUploading>
                </div>

                
                <div className='form-group mt-3'>
                    <label for='Bank / Credit Slogan '><strong>Slogan:</strong></label>
                    <input type='text' name='Bank Name' required className='form-control' value={slogan} onChange={(e)=>setSlogan(e.target.value)}/>
                </div>

                <div className='form-group mt-3'>
                    <label for='Bank / Credit Mission'><strong>Mission:</strong></label>
                    <input type='text' name='Bank Name' required className='form-control'  value={mission} onChange={(e)=>setMission(e.target.value)} />
                </div>

                <div className='form-group mt-3'>
                    <label for='Bank / Credit Mission'><strong>Vission:</strong></label>
                    <input type='text' name='Bank Vission' required className='form-control' onChange={(e)=>setVission(e.target.value)} value={vission} />
                </div>

                <button onClick={UpdateBankInfo} className='btn-class mt-3 mb-5'>Save</button>

            </div>

            
        </div>
        </div>
    </div>
  )
}

export default Bank
