import { VAULT_SET_REQUEST ,VAULT_SET_SUCCESS ,VAULT_SET_FAIL ,VAULT_SET_RESET ,

    VAULT_INFO_REQUEST ,VAULT_INFO_SUCCESS ,VAULT_INFO_FAIL ,VAULT_INFO_RESET ,

    VAULT_DETAILS_REQUEST ,VAULT_DETAILS_SUCCESS ,VAULT_DETAILS_FAIL,VAULT_DETAILS_RESET ,

    VAULT_UPDATE_REQUEST,VAULT_UPDATE_SUCCESS ,VAULT_UPDATE_FAIL ,VAULT_UPDATE_RESET ,

    ACTIVE_BRANCH_VAULT_REQUEST, ACTIVE_BRANCH_VAULT_SUCCESS ,ACTIVE_BRANCH_VAULT_FAIL ,ACTIVE_BRANCH_VAULT_RESET ,

    VAULT_BALANCE_REQUEST ,VAULT_BALANCE_SUCCESS ,VAULT_BALANCE_FAIL ,VAULT_BALANCE_RESET ,

    USER_VAULT_REQUEST_SAVE ,USER_VAULT_BALANCE_SUCCESS_SAVE ,USER_VAULT_BALANCE_FAIL_SAVE ,USER_VAULT_BALANCE_RESET_SAVE ,

    TREASURE_TODAY_REQUEST ,TREASURE_TODAY_SUCCESS ,TREASURE_TODAY_FAIL ,TREASURE_TODAY_RESET ,

    TREASURE_SETTING_REQUEST ,TREASURE_SETTING_SUCCESS ,TREASURE_SETTING_FAIL ,TREASURE_SETTING_RESET ,

    TREASURE_SETTING_REQUEST_LOAD ,TREASURE_SETTING_SUCCESS_LOAD ,TREASURE_SETTING_FAIL_LOAD ,TREASURE_SETTING_RESET_LOAD ,

    VAULT_REQUEST_SAVE,VAULT_SUCCESS_SAVE ,VAULT_FAIL_SAVE ,VAULT_RESET_SAVE ,

    UPDATE_USER_VAULT_REQUEST , UPDATE_USER_VAULT_SUCCESS , UPDATE_USER_VAULT_FAIL , UPDATE_USER_VAULT_RESET ,

    USER_VAULT_REQUEST ,USER_VAULT_SUCCESS ,USER_VAULT_FAIL ,USER_VAULT_RESET,

    MOBILE_COLL_ASSC_REQUEST ,MOBILE_COLL_ASSC_SUCCESS ,MOBILE_COLL_ASSC_FAIL , MOBILE_COLL_ASSC_RESET

} from "../constants/vaultConstants";



export const createVaultReducer=(state={},action)=>{

    switch(action.type){
        case VAULT_SET_REQUEST :
            return{creatValutLoading:true  }

        case VAULT_SET_SUCCESS :
            return{creatValutLoading:false , vaultsu:true , creatValut:action.payload}  

        case VAULT_SET_FAIL :
            return {creatValutLoading:false , error:action.payload}

        case VAULT_SET_RESET:
            return{}
        
        default:
            return state

    }

}

export const vaultReducer=(state={vault:[]},action)=>{

    switch(action.type){
        case VAULT_INFO_REQUEST :
            return{vaultLoading:true ,...state }

        case VAULT_INFO_SUCCESS :
            return{vaultLoading:false , vault:action.payload}  

        case VAULT_INFO_FAIL :
            return {vaultLoading:false , error:action.payload}

        case VAULT_INFO_RESET:
            return{vault:[]}
        
        default:
            return state

    }

}

export const VaultDetailsReducer=(state={VaultDetailsInfo:{}},action)=>{

    switch(action.type){
        case VAULT_DETAILS_REQUEST :
            return{vaultDetailsLoading:true ,...state  }

        case VAULT_DETAILS_SUCCESS :
            return{vaultDetailsLoading:false , vaultDetailssuccess:true , VaultDetailsInfo:action.payload}  

        case VAULT_DETAILS_FAIL :
            return {vaultDetailsLoading:false , error:action.payload}

        case VAULT_DETAILS_RESET:
            return{}
        
        default:
            return state

    }

}


export const aprovelVaultReducer=(state={},action)=>{

    switch(action.type){
        case VAULT_UPDATE_REQUEST :
            return{UpdateValutLoading:true  }

        case VAULT_UPDATE_SUCCESS :
            return{UpdateValutLoading:false , updatevaultsu:true , updateValut:action.payload}  

        case VAULT_UPDATE_FAIL :
            return {UpdateValutLoading:false , error:action.payload}

        case VAULT_UPDATE_RESET:
            return{}
        
        default:
            return state

    }

}


export const branchVaultReducer=(state={branchActiveVault:[]},action)=>{

    switch(action.type){
        case ACTIVE_BRANCH_VAULT_REQUEST :
            return{branchActiveVaultLoading:true ,...state }

        case ACTIVE_BRANCH_VAULT_SUCCESS :
            return{branchActiveVaultLoading:false , branchActiveVault:action.payload}  

        case ACTIVE_BRANCH_VAULT_FAIL :
            return {branchActiveVaultLoading:false , error:action.payload}

        case ACTIVE_BRANCH_VAULT_RESET:
            return{branchActiveVault:{}}
        
        default:
            return state

    }

}


export const VaultBalanceReducer=(state={VaultBalance:[]},action)=>{

    switch(action.type){
        case VAULT_BALANCE_REQUEST :
            return{VaultBalanceLoading:true ,...state }

        case VAULT_BALANCE_SUCCESS :
            return{VaultBalanceLoading:false , VaultBalance:action.payload}  

        case VAULT_BALANCE_FAIL :
            return {VaultBalanceLoading:false , error:action.payload}

        case VAULT_BALANCE_RESET:
            return{VaultBalance:{}}
        
        default:
            return state

    }

}


export const createUserVaultReducer=(state={},action)=>{

    switch(action.type){
        case USER_VAULT_REQUEST_SAVE :
            return{creatUserValutLoading:true  }

        case USER_VAULT_BALANCE_SUCCESS_SAVE :
            return{creatUserValutLoading:false , Uservaultsu:true , creatUserValut:action.payload}  

        case USER_VAULT_BALANCE_FAIL_SAVE :
            return {creatUserValutLoading:false , error:action.payload}

        case USER_VAULT_BALANCE_RESET_SAVE:
            return{}
        
        default:
            return state

    }

}


export const TreasureTodayReducer=(state={TreasureToday:[]},action)=>{

    switch(action.type){
        case TREASURE_TODAY_REQUEST :
            return{TreasureTodayLoading:true ,...state }

        case TREASURE_TODAY_SUCCESS :
            return{TreasureTodayLoading:false , TreasureToday:action.payload}  

        case TREASURE_TODAY_FAIL :
            return {TreasureTodayLoading:false , error:action.payload}

        case TREASURE_TODAY_RESET:
            return{TreasureToday:[]}
        
        default:
            return state

    }

}


export const createUserVaultSettingReducer=(state={},action)=>{

    switch(action.type){
        case TREASURE_SETTING_REQUEST :
            return{creatUserSettingValutLoading:true  }

        case TREASURE_SETTING_SUCCESS :
            return{creatUserSettingValutLoading:false , creatUserSettingValutSu:true ,  creatUserSettingValut:action.payload}  

        case TREASURE_SETTING_FAIL :
            return {creatUserSettingValutLoading:false , creatUserSettingerror:action.payload}

        case TREASURE_SETTING_RESET:
            return{}
        
        default:
            return state

    }

}


export const TreasureTodaySettingReducer=(state={TreasureSettingToday:[]},action)=>{

    switch(action.type){
        case TREASURE_SETTING_REQUEST_LOAD :
            return{TreasureTodaySettingLoading:true ,...state }

        case TREASURE_SETTING_SUCCESS_LOAD :
            return{TreasureTodaySettingLoading:false , TreasureSettingToday:action.payload}  

        case TREASURE_SETTING_FAIL_LOAD :
            return {TreasureTodaySettingLoading:false , error:action.payload}

        case TREASURE_SETTING_RESET_LOAD:
            return{TreasureSettingToday:{}}
        
        default:
            return state

    }

}


export const saveVaultTransactionReducer=(state={},action)=>{

    switch(action.type){
        case VAULT_REQUEST_SAVE :
            return{saveVaultTransactionLoading:true }

        case VAULT_SUCCESS_SAVE :
            return{saveVaultTransactionLoading:false  , saveVault:true, VaultTransaction:action.payload}  

        case VAULT_FAIL_SAVE :
            return {saveVaultTransactionLoading:false , error:action.payload}

        case VAULT_RESET_SAVE:
            return{}
        
        default:
            return state

    }

}

export const updateUserVaultReducer=(state={},action)=>{

    switch(action.type){
        case UPDATE_USER_VAULT_REQUEST :
            return{updateUserVaultLoading:true  }

        case UPDATE_USER_VAULT_SUCCESS :
            return{updateUserVaultLoading:false , valutup:true, updateUserVault:action.payload}  

        case UPDATE_USER_VAULT_FAIL :
            return {updateUserVaultLoading:false , error:action.payload}

        case UPDATE_USER_VAULT_RESET:
            return{}
        
        default:
            return state

    }

}


export const UserTreasureTodayReducer=(state={ UserTreasureToday:[]},action)=>{

    switch(action.type){
        case USER_VAULT_REQUEST :
            return{ UserTreasureTodayLoading:true ,...state }

        case USER_VAULT_SUCCESS :
            return{ UserTreasureTodayLoading:false ,  UserTreasureToday:action.payload}  

        case USER_VAULT_FAIL :
            return { UserTreasureTodayLoading:false , error:action.payload}

        case USER_VAULT_RESET:
            return{ UserTreasureToday:{}}
        
        default:
            return state

    }

}

export const MobileCollReducer=(state={ mobCollToday:[]},action)=>{

    switch(action.type){
        case MOBILE_COLL_ASSC_REQUEST :
            return{ mobCollTodayLoading:true ,...state }

        case MOBILE_COLL_ASSC_SUCCESS :
            return{ mobCollTodayLoading:false ,  mobCollToday:action.payload}  

        case MOBILE_COLL_ASSC_FAIL :
            return { mobCollTodayLoading:false , mobCollTodayerror:action.payload}

        case MOBILE_COLL_ASSC_RESET:
            return{ mobCollToday:{}}
        
        default:
            return state

    }

}