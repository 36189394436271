import { CREATE_ACCOUNT_REQUEST,CREATE_ACCOUNT_SUCCESS,CREATE_ACCOUNT_FAIL ,
   CUSTOMER_ACCOUNTS_REQUEST ,CUSTOMER_ACCOUNTS_SUCCESS ,CUSTOMER_ACCOUNTS_FAIL ,

   CUSTOMER_ACCOUNTS_REQUEST_DETAILS,CUSTOMER_ACCOUNTS_SUCCESS_DETAILS,CUSTOMER_ACCOUNTS_FAIL_DETAILS ,

   CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS ,CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS ,CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS ,

   CUSTOMER_ACCOUNTS_NUMBER_REQUEST ,CUSTOMER_ACCOUNTS_NUMBER_SUCCESS ,CUSTOMER_ACCOUNTS_NUMBER_FAIL ,

   SAVE_AUTO_REQUEST,SAVE_AUTO_SUCCESS ,SAVE_AUTO_FAIL,

   LOAD_AUTO_REQUEST ,LOAD_AUTO_SUCCESS ,LOAD_AUTO_FAIL ,
   
   DEPOSIT_REQUEST,DEPOSIT_SUCCESS,DEPOSIT_FAIL
   
   ,ACCOUNT_TRANSACTION_REQUEST , ACCOUNT_TRANSACTION_SUCCESS ,ACCOUNT_TRANSACTION_FAIL ,

   ACCOUNT_BALANCE_REQUEST ,ACCOUNT_BALANCE_SUCCESS ,ACCOUNT_BALANCE_FAIL ,

   USER_DEPOSIT_TOTAL_REQUEST ,USER_DEPOSIT_TOTAL_SUCCESS ,USER_DEPOSIT_TOTAL_FAIL  ,

   CREATE_CORACCOUNT_REQUEST ,CREATE_CORACCOUNT_SUCCESS  ,CREATE_CORACCOUNT_FAIL ,

   CREATE_SIGNATORY_REQUEST ,CREATE_SIGNATORY_SUCCESS,CREATE_SIGNATORY_FAIL ,

   DETAILS_CORACCOUNT_REQUEST ,DETAILS_CORACCOUNT_SUCCESS ,DETAILS_CORACCOUNT_FAIL ,

    DETAILS_SIGNATORY_REQUEST ,DETAILS_SIGNATORY_SUCCESS ,DETAILS_SIGNATORY_FAIL  ,

    ACCOUNT_AUTO_SERVICE_REQUEST ,ACCOUNT_AUTO_SERVICE_SUCCESS ,ACCOUNT_AUTO_SERVICE_FAIL,

    ACCOUNT_HISTORY_REQUEST ,ACCOUNT_HISTORY_SUCCESS ,ACCOUNT_HISTORY_FAIL  ,

    MOB_ACCOUNT_TRANSACTION_REQUEST,MOB_ACCOUNT_TRANSACTION_SUCCESS ,MOB_ACCOUNT_TRANSACTION_FAIL ,

    MOB_ACCOUNT_TRANSACTION_UPDATE_REQUEST ,MOB_ACCOUNT_TRANSACTION_UPDATE_SUCCESS ,MOB_ACCOUNT_TRANSACTION_UPDATE_FAIL ,
 
} from "../constants/customerAccountConstants";


import axios from "axios";

export const createCustomerAccounAction =(Customer_Account) => async (dispatch)=>{

    try {
 
       dispatch({type:CREATE_ACCOUNT_REQUEST})
 
       const config = {header : {'content-type': 'application/json'} } 

       const {data} = await axios.put(`api/createCustomerAccount/${Customer_Account.customer_id_id}/`,Customer_Account ,config)
 
       
       dispatch({type:CREATE_ACCOUNT_SUCCESS , payload:data})
 
      
    } catch (error) {
 
       dispatch({type:CREATE_ACCOUNT_FAIL , 
          payload:error.response && error.response.data.detail ?
          error.response.data.detail:
          error.message,
  })
       
    }
 
  }


export const createCustomerBankAccounAction = (customer_id_id,product_name,product_code,account_number,account_name,branch,dat ,initial ,signature,level,save_by 
   ,acc_type ,bank_code ,branch_code ,customer_number,signature2,signature3,picone ,pictwo ,picthree ,sms_number ,card_ty ,card_Id ,card_ty_2 ,card_Id_2 ,card_ty_3
    ,card_Id_3,na1,tel1,na2,tel2,na3,tel3) => async(dispatch) =>{
   try {
      dispatch({type:CREATE_ACCOUNT_REQUEST})
  
      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/createBankAccount/' , 
      {'customer_id_id': customer_id_id, 'product_name':product_name ,'product_code':product_code,'account_number':account_number ,'account_name':account_name,
         'branch':branch,'dat':dat ,'initial':initial ,'signature':signature,'level':level ,'save_by':save_by ,'acc_type':acc_type ,'bank_code':bank_code,
         'branch_code':branch_code, 'customer_number':customer_number,  'signature2':signature2, 'signature3':signature3 , 'picone':picone ,'pictwo':pictwo 
          ,'picthree':picthree ,'sms_number':sms_number ,'card_ty':card_ty ,'card_Id':card_Id ,'card_ty_2':card_ty_2 ,'card_Id_2':card_Id_2 ,'card_ty_3':card_ty_3,
          'card_Id_3':card_Id_3 ,'na1':na1,'tel1':tel1 ,'na2':na2 ,'tel2':tel2 ,'na3':na3 ,'tel3':tel3} ,config)

      dispatch({type: CREATE_ACCOUNT_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_ACCOUNT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

export const ApproveCreateCustomerAccounAction =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:CREATE_ACCOUNT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/approveCreateAccountSave/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:CREATE_ACCOUNT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:CREATE_ACCOUNT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const loadCustomerAccountAction =() => async (dispatch)=>{

   try {

      dispatch({type:CUSTOMER_ACCOUNTS_REQUEST})

     
      const {data} = await axios.get('api/getCustomerInfo/')

      
      dispatch({type:CUSTOMER_ACCOUNTS_SUCCESS , payload:data})

      //localStorage.setItem('loadApprovals' ,JSON.stringify(data))
   

   } catch (error) {

      dispatch({type:CUSTOMER_ACCOUNTS_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const getCustomerDetailsAction=(trans_code)=>async(dispatch)=>{
   try {

       dispatch({type:CUSTOMER_ACCOUNTS_REQUEST_DETAILS})

   
       const {data} = await axios.get(`api/getCustomerAccountDetails/${trans_code}`)

       dispatch({type:CUSTOMER_ACCOUNTS_SUCCESS_DETAILS , payload:data})

      // localStorage.setItem('bankInfo' , JSON.stringify(data))
       
   } catch (error) {
       dispatch({
           type : CUSTOMER_ACCOUNTS_FAIL_DETAILS , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
   }

}

export const CustomerAccountTypeAction =(customer_id_id) => async (dispatch)=>{

   try {

      dispatch({type:CUSTOMER_ACCOUNTS_TYPE_REQUEST_DETAILS})

     
      const {data} = await axios.get(`api/CustomerAccountTypeDetails/?customer_id_id=${customer_id_id}`)

      
      dispatch({type:CUSTOMER_ACCOUNTS_TYPE_SUCCESS_DETAILS , payload:data})


   } catch (error) {

      dispatch({type:CUSTOMER_ACCOUNTS_TYPE_FAIL_DETAILS , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const loadCustomerAccountNumberAction =(save_aproval) => async (dispatch)=>{

   try {

      dispatch({type:CUSTOMER_ACCOUNTS_NUMBER_REQUEST})

     
      const {data} = await axios.get(`api/Customer_Accoun_Number/?save_aproval=${save_aproval}`)

      
      dispatch({type:CUSTOMER_ACCOUNTS_NUMBER_SUCCESS , payload:data})

      localStorage.setItem('accountNumber' ,JSON.stringify(data))
   

   } catch (error) {

      dispatch({type:CUSTOMER_ACCOUNTS_NUMBER_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const SaveAutoAction = (account_number , account_name, product_name , branch , acc_type ,service_code ,service_name,amount,dat,level,save_by ,tel ,email ) => async(dispatch) =>{
   
   try {

      dispatch({type:SAVE_AUTO_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveAutoSerivce/' , 
      {'account_number': account_number,  'account_name':account_name ,'product_name':product_name ,'branch':branch ,'acc_type':acc_type ,
         'service_code':service_code ,'service_name':service_name,'amount':amount,'dat':dat,'level':level,'save_by':save_by ,'tel':tel , 'email':email}  ,config)

      dispatch({type: SAVE_AUTO_SUCCESS , payload: data})
      
     

   } catch (error) {

      dispatch({type:SAVE_AUTO_FAIL  , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const CustomerAccountAutoServicesAction =(account_number) => async (dispatch)=>{

   try { 

      dispatch({type:LOAD_AUTO_REQUEST})

     
      const {data} = await axios.get(`api/customerAutoServices/?account_number=${account_number}`)

      
      dispatch({type:LOAD_AUTO_SUCCESS , payload:data})

      //localStorage.setItem('autoServices' ,JSON.stringify(data))
   

   } catch (error) {

      dispatch({type:LOAD_AUTO_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 

 export const DepositAccounAction =(customer_transcation) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/crCustimerAccount/${customer_transcation.trans_code}/`,customer_transcation ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const UsateSigAccounAction =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateCustomerAccount/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 //Update Cop-Account Signature 

 
 export const UsateSigAccounActionsig1 =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateCustomerAccountsig1/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const UsateSigAccounActionsig2 =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateCustomerAccountsig2/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const UsateSigAccounActionsig3 =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateCustomerAccountsigg/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 //update Account Name

 export const updateAccountName =(Customer_Account) => async (dispatch)=>{

   try {

      dispatch({type:DEPOSIT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateNewAccount/${Customer_Account.trans_code}/`,Customer_Account ,config)

      
      dispatch({type:DEPOSIT_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:DEPOSIT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const SaveAccountTransaction = (cr,dat,amount ,dr ,branch ,account_number ,des ,dat2 ,account_name ,product_name ,user ,trans_by,tel ,idtype ,idnumber,vault_id 
   ,n200,n100,n50,n20,n10,n5,n2,n1,c2,c1,c05,c02,c01,amount_word) => async(dispatch) =>{
   
   try {

      dispatch({type:ACCOUNT_TRANSACTION_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/accountTransaction/' , 
      {'cr': cr ,'dat':dat, 'amount':amount  ,'dr':dr ,'branch':branch , 'account_number':account_number ,
         'des':des , 'dat2':dat2 , 'account_name':account_name,'product_name':product_name ,'user':user ,'trans_by':trans_by,'tel':tel ,'idtype':idtype ,'idnumber':idnumber 
         ,'vault_id':vault_id ,'n200':n200, 'n100':n100 , 'n50':n50 ,'n20':n20 , 'n10':n10 ,'n5':n5 , 'n2':n2 , 'n1':n1 ,'c2':c2 ,'c1':c1 ,'c05':c05, 
         'c02':c02, 'c01':c01, 'amount_word':amount_word }  ,config)

      dispatch({type: ACCOUNT_TRANSACTION_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:ACCOUNT_TRANSACTION_FAIL    , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const CustomerAccountBalanceAction =(account_number) => async (dispatch)=>{

   try {

      dispatch({type:ACCOUNT_BALANCE_REQUEST})

     
      const {data} = await axios.get(`api/Customer_Account_balance/?account_number=${account_number}`)

      
      dispatch({type:ACCOUNT_BALANCE_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:ACCOUNT_BALANCE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 
 export const userAccountBalanceAction =(dat2 ,user ,vault_id) => async (dispatch)=>{

   try {

      dispatch({type:USER_DEPOSIT_TOTAL_REQUEST})

     
      const {data} = await axios.get(`api/User_Account_balance/?dat2=${dat2}&user=${user}&vault_id=${vault_id}`)

      
      dispatch({type:USER_DEPOSIT_TOTAL_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:USER_DEPOSIT_TOTAL_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 export const createCustomercompanyDetail = (member_id_id , company ,cer_reg ,dat_reg,jur_reg,cat_bus,nat_bus,sec,bus_add,cor_bus_add,busemail,web,phone,tin,bus_no,other_refe,anu_turn) => async(dispatch) =>{
   try {
      dispatch({type:CREATE_CORACCOUNT_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/company_account/' , 
      {'member_id_id': member_id_id, 'company':company ,'cer_reg':cer_reg,'dat_reg':dat_reg ,'jur_reg':jur_reg,'cat_bus':cat_bus,'nat_bus':nat_bus ,
         'sec':sec ,'bus_add':bus_add,'cor_bus_add':cor_bus_add ,'busemail':busemail ,'web':web ,'phone':phone,'tin':tin, 'bus_no':bus_no ,'other_refe':other_refe ,'anu_turn':anu_turn} ,config)

      dispatch({type: CREATE_CORACCOUNT_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_CORACCOUNT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const editCustomercompanysignatory = (card , card_no , assig ,assig2 ,assig3 ,table_pk,card_2 ,card_no_2 ,card_3 ,card_no_3 ,pic ,pic2 ,pic3) => async(dispatch) =>{
   try {
      dispatch({type:CREATE_SIGNATORY_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveEditAccountSignatory/' , 
      {'card': card, 'card_no':card_no ,'assig':assig,'assig2':assig2 ,'assig3':assig3,'table_pk':table_pk ,'card_2':card_2 ,'card_no_2':card_no_2 ,'card_3':card_3 
         ,'card_no_3':card_no_3 ,'pic':pic ,'pic2':pic2,'pic3':pic3} ,config)

      dispatch({type: CREATE_SIGNATORY_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_SIGNATORY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }



 //Post cor-account Edit

 export const editCustomercompanysignatorycor = (card , card_no , assig ,assig2  ,table_pk, pic ,pic2 ) => async(dispatch) =>{
   try {
      dispatch({type:CREATE_SIGNATORY_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/saveEditAccountSignatoryCor/' , 
      {'card': card, 'card_no':card_no ,'assig':assig ,'assig2':assig2 ,'table_pk':table_pk , 'pic':pic ,'pic2':pic2 } ,config)

      dispatch({type: CREATE_SIGNATORY_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_SIGNATORY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 //change Acc Name
 export const changeAcountName = (card ,table_pk ) => async(dispatch) =>{
   try {
      dispatch({type:CREATE_SIGNATORY_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/changeAcountName/' , 
      {'card': card, 'table_pk':table_pk  } ,config)

      dispatch({type: CREATE_SIGNATORY_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_SIGNATORY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }
 

 export const createCustomercompanysignatory = (member_id_id , 
     asname1 ,asdob1 ,asgen1,asmei1,asnat1,aspn1,asid1,asidno1,asiddat1,asidex1,asocc1,asjt1,aspos1,asresadd1,asnea1,ascity1,asemail1,assig1,

     asname2 ,asdob2 ,asgen2,asmei2,asnat2,aspn2,asid2,asidno2,asiddat2,asidex2,asocc2,asjt2,aspos2,asresadd2,asnea2,ascity2,asemail2,assig2, 

     asname3 ,asdob3 ,asgen3,asmei3,asnat3,aspn3,asid3,asidno3,asiddat3,asidex3,asocc3,asjt3,aspos3,asresadd3,asnea3,ascity3,asemail3,assig3

 ) => async(dispatch) =>{
   
   try {
      dispatch({type:CREATE_SIGNATORY_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const { data } = await axios.post('api/Signatory/' ,
         
      {'member_id_id': member_id_id, 'asname1':asname1 ,'asdob1':asdob1,'asgen1':asgen1 ,'asmei1':asmei1,'asnat1':asnat1,'aspn1':aspn1 ,
         'asid1':asid1 ,'asidno1':asidno1,'asiddat1':asiddat1 ,'asidex1':asidex1 ,'asocc1':asocc1 ,'asjt1':asjt1,'aspos1':aspos1, 
         'asresadd1':asresadd1 ,'asnea1':asnea1 ,'ascity1':ascity1,'asemail1':asemail1 ,'assig1':assig1 ,
         
         'asname2':asname2 ,'asdob2':asdob2,'asgen2':asgen2 ,'asmei2':asmei2,'asnat2':asnat2,'aspn2':aspn2 ,
         'asid2':asid2 ,'asidno2':asidno2,'asiddat2':asiddat2 ,'asidex2':asidex2 ,'asocc2':asocc2 ,'asjt2':asjt2,'aspos2':aspos2, 
         'asresadd2':asresadd2 ,'asnea2':asnea2 ,'ascity2':ascity2,'asemail2':asemail2 ,'assig2':assig2,   
         
         'asname3':asname3 ,'asdob3':asdob3,'asgen3':asgen3 ,'asmei3':asmei3,'asnat3':asnat3,'aspn3':aspn3 ,
         'asid3':asid3 ,'asidno3':asidno3,'asiddat3':asiddat3 ,'asidex3':asidex3 ,'asocc3':asocc3 ,'asjt3':asjt3,'aspos3':aspos3, 
         'asresadd3':asresadd3 ,'asnea3':asnea3 ,'ascity3':ascity3,'asemail3':asemail3 ,'assig3':assig3  } ,config)

      dispatch({type: CREATE_SIGNATORY_SUCCESS , payload: data})
      
      //localStorage.setItem('userInfo' ,JSON.stringify(data))

   } catch (error) {

      dispatch({type:CREATE_SIGNATORY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }




 
export const getMemberCorAaccountDetailsAction=(member_id_id)=>async(dispatch)=>{
   try {

       dispatch({type:DETAILS_CORACCOUNT_REQUEST})

   
       const {data} = await axios.get(`api/getCorcompany/${member_id_id}`)

       dispatch({type:DETAILS_CORACCOUNT_SUCCESS , payload:data})

      // localStorage.setItem('bankInfo' , JSON.stringify(data))
       
   } catch (error) {
       dispatch({
           type : DETAILS_CORACCOUNT_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
   }

}



export const getMemberCorAaccountSignatoryDetailsAction=(member_id_id)=>async(dispatch)=>{
   try {

       dispatch({type:DETAILS_SIGNATORY_REQUEST})

   
       const {data} = await axios.get(`api/getSignatory/${member_id_id}`)

       dispatch({type:DETAILS_SIGNATORY_SUCCESS , payload:data})

      // localStorage.setItem('bankInfo' , JSON.stringify(data))
       
   } catch (error) {
       dispatch({
           type : DETAILS_SIGNATORY_FAIL , 
           payload : error.response && error.response.data.message
           ? error.response.data.message
           : error.message,
   
   })
   }

}



export const getAaccountSignatoryDetailsEditAction =(table_pk ,approval_status ) => async (dispatch)=>{

   try {

      dispatch({type:DETAILS_SIGNATORY_REQUEST})

     
      const {data} = await axios.get(`api/editSig/?table_pk=${table_pk}&approval_status=${approval_status}`)

      
      dispatch({type:DETAILS_SIGNATORY_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:DETAILS_CORACCOUNT_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


export const AccountAountServicerAction =(account_number ,save_aproval) => async (dispatch)=>{

   try {

      dispatch({type:ACCOUNT_AUTO_SERVICE_REQUEST})

     
      const {data} = await axios.get(`api/Customer_Account_services/?account_number=${account_number}&save_aproval=${save_aproval}`)

      
      dispatch({type:ACCOUNT_AUTO_SERVICE_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:ACCOUNT_AUTO_SERVICE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }




 export const customerAccountHistory =(account_number ) => async (dispatch)=>{

   try {

      dispatch({type:ACCOUNT_HISTORY_REQUEST})

     
      const {data} = await axios.get(`api/Customer_Account_History/?account_number=${account_number}`)

      
      dispatch({type:ACCOUNT_HISTORY_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:ACCOUNT_HISTORY_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }


 export const MOBAccountTransactionAction =(dat2 ,user) => async (dispatch)=>{

   try {

      dispatch({type:MOB_ACCOUNT_TRANSACTION_REQUEST})

     
      const {data} = await axios.get(`api/mobile_collected_amount/?dat2=${dat2}&user=${user}`)

      
      dispatch({type:MOB_ACCOUNT_TRANSACTION_SUCCESS , payload:data})

      
   } catch (error) {

      dispatch({type:MOB_ACCOUNT_TRANSACTION_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }

 

 export const MOBTransactionUpdateAction =(mob) => async (dispatch)=>{

   try {

      dispatch({type:MOB_ACCOUNT_TRANSACTION_UPDATE_REQUEST})

      const config = {header : {'content-type': 'application/json'} } 

      const {data} = await axios.put(`api/updateMOBDeposit/${mob.trans_code}/`,mob ,config)

      
      dispatch({type:MOB_ACCOUNT_TRANSACTION_UPDATE_SUCCESS , payload:data})

     
   } catch (error) {

      dispatch({type:MOB_ACCOUNT_TRANSACTION_UPDATE_FAIL , 
         payload:error.response && error.response.data.detail ?
         error.response.data.detail:
         error.message,
 })
      
   }

 }