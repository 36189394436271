import React  ,{useState ,useEffect }from 'react'
import { useDispatch ,useSelector } from 'react-redux';
import Stepper from "awesome-react-stepper";
import ImageUploading from "react-images-uploading";
import { GrDocumentUpdate } from "react-icons/gr";
import { MdDeleteForever } from "react-icons/md";
import { CiSearch } from "react-icons/ci";
import { Link, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2'
import Loader from '../Components/Loader';
import { updateMemberInfoAccount } from '../actions/memberAction';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import moment from 'moment'
import { loadCustomerAccountAction  ,createCustomerBankAccounAction  ,CustomerAccountTypeAction} from '../actions/customerAccountAction';
import { CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS } from '../constants/customerAccountConstants';
import { BiSolidReport } from "react-icons/bi";
import DataTable from 'react-data-table-component'
import { MEMBER_RESET_DETAILS } from '../constants/memberConstants';
import { CREATE_ACCOUNT_RESET } from '../constants/customerAccountConstants';
import profile from '../images/profile-img.jpg'
import { MdOutlineEdit } from "react-icons/md";
import { aprMessageAction ,messageListAction } from '../actions/approvalAction';
import { APPROVAL_MESSAGE_SAVE_REST } from '../constants/approvalConstants';
import { Modal } from 'react-bootstrap';
import useSound from 'use-sound';
import messagersent from '../images/messagersent.wav'
import { TbReportSearch } from "react-icons/tb";

function IndividualAcc() {

    const [playOn] = useSound(
        messagersent ,
        { volume: 0.25 }
      );

  const dispatch=useDispatch()
  var zeroFill = require('zero-fill')
  
 const[prousers, setprousers]=useState([])

 let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const [signature ,setSignature]=useState('')

  const [approval_byb ,setapproval_byb]=useState('')

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);

    if(imageList.length < 1){

        setSignature(bankInfo.image)

    }else {

     setSignature(imageList[0].data_url)
      //console.log(imageList[0].data_url);

    }
   
  };
  
  const[code,setCode]=useState('')
  const [memberid ,setmemberid]=useState('')
  const [pic ,setpic]=useState('')
  const [sex ,SetSex]=useState('')
  const [account_name ,setname]=useState('')
  const [r_add ,setr_add]=useState('')
  const [pob ,setpob]=useState('')
  const[reg ,setreg]=useState('')
  const [nland ,setnland]=useState('')
  const[tel ,settel]=useState('')
  const[email ,setemail]=useState('')
  const[dob ,setdob]=useState('')
  const [city ,setcity]=useState('')
  const [edu ,setedu]=useState('')
  const[mar ,setmar]=useState('')
  const[card_Id ,setcard_Id]=useState('')
  const[card_ty,setcard_ty]=useState('')
  const[card_is_dat ,setcard_is_dat]=useState('')
  const[card_ex_date,setcard_ex_date]=useState('')
  
  const [income_s ,setincome_s]=useState('')
  const [b_long ,Setb_long]=useState('')
  const [bus ,setbus]=useState('')
  const [b_reg ,setb_reg]=useState('')
  const [b_reg_no ,setb_reg_no]=useState('')
  const[dat_r ,setdat_r]=useState('')
  const [dat_in ,setdat_in]=useState('')
  const[nat_b ,setnat_b]=useState('')
  const[founder ,setfounder]=useState('')
  const[found_con ,setfound_con]=useState('')
  const [bus_loc ,setbus_loc]=useState('')
  
  const [nk1 ,setnk1]=useState('')
  const[nkc1 ,setnkc1]=useState('')
  const[nkr1 ,setnkr1]=useState('')
  const[nkb1 ,setnkb1]=useState('')
  
  const [nk2 ,setnk2]=useState('')
  const[nkc2 ,setnkc2]=useState('')
  const[nkr2 ,setnkr2]=useState('')
  const[nkb2 ,setnkb2]=useState('')

 const [product_name ,setGetProduct]=useState('')
 const [initial,setInitial]=useState('')
 
 const[other_info ,setother_info]=useState('')
 
 const [bill_dat ,setbill_dat]=useState('')
 const [bill_address ,setbill_address]=useState('')
 const [bill_details ,setbill_details]=useState('')
 const [bill_tel ,setbill_tel]=useState('')

 const [rname1 ,setrname1]=useState('')
 const [rtel1 ,setrtel1]=useState('')
 const [rrel1 ,setrrel1]=useState('')
 const [raddress1 ,setraddress1]=useState('')

 const [rname2 ,setrname2]=useState('')
 const [rtel2 ,setrtel2]=useState('')
 const [rrel2 ,setrrel2]=useState('')
 const [raddress2 ,setraddress2]=useState('')
const [bank_code ,setbank_code]=useState('')
const [branch_code ,setbranch_code]=useState('')
const [customer_number ,setcustomer_number]=useState('')

const [prouser, setprouser] = useState([])

const [Request ,setRequest]=useState(false)

const[datRequsr ,setdatRequsr]=useState(dat)

const [RequestMessage ,setRequestMessage]=useState( )

const [totalUserMessages ,settotalUserMessages]=useState()

const [approval_by ,setapproval_by]=useState('')

const [selectedRow,setselectedRow]=useState()

const [whyMessageRequst ,setwhyMessageRequst]=useState('')




  const MembereDetailsReducer = useSelector(state => state. MembereDetailsReducer)
  const {MemberDetailsInfo ,MemberDetailssuccess ,memberDetailsLoading}= MembereDetailsReducer


  const userProfileReducer = useSelector(state => state.userProfileReducer)
  const { userProfileInfo }=userProfileReducer

  const  bankInfoReducer = useSelector(state => state. bankInfoReducer)
  const {bankInfo}= bankInfoReducer
  const[account_number ,setaccount_number]=useState('')


const  branchDetailsReducer = useSelector(state => state.branchDetailsReducer)
const {branchsInfo}= branchDetailsReducer

const seriveReducer = useSelector(state => state.seriveReducer)


const productReducer = useSelector(state => state.productReducer)
const { products ,loadingproduct }=productReducer

const [branch ,setBranch]=useState(userProfileInfo.branch)
const [product_code,setProductCode]=useState('')


const userLogin = useSelector(state => state.userLogin)
const { userInfo }=userLogin


const CustomerAccountReducer = useSelector(state => state.CustomerAccountReducer)
const {customerAccounts ,customerAccountsLoading}= CustomerAccountReducer

const [getIndAcc ,setgetIndAcc]=useState([])

const [getIndAccDate , setgetIndAccDate]=useState(getIndAcc) 


const CustomerAccountTypeDetailsReducer = useSelector(state => state. CustomerAccountTypeDetailsReducer)
const {AccountTypeDetails}= CustomerAccountTypeDetailsReducer 

const  createBankAccountReducer = useSelector(state => state. createBankAccountReducer)
const {customerAccountUpdateLoading ,creataccout ,coraccsufferror ,error ,accountInfo}= createBankAccountReducer


const ALLUserReducer = useSelector(state => state. ALLUserReducer)
const {AllUser ,UserLoading ,UserSuccess}= ALLUserReducer


const apro_Message_Reducer = useSelector(state => state.apro_Message_Reducer)
const {aproMessageLoading ,message,success}= apro_Message_Reducer


const save_Message_Reducer = useSelector(state => state.save_Message_Reducer)
const {messagesave ,messagesaveLoading,savesuccess}= save_Message_Reducer

 

const Accountcolumns =[
    {
     name:'Id' ,
     selector:row=>row.trans_code ,
     width: "100px" 
     
     ,wrap:true 

    } ,

     {
      name:'Product Name' ,
      selector:row=>row.product_name,
      width: "150px" 
      ,wrap:true
     },


     {
        name:'Account Name' ,
        selector:row=>row.account_name,
        width: "px" 
        ,wrap:true
       },

     {
      name:'Account Number',
      selector:row=>row.account_number ,
      width: "200px" 
      ,wrap:true ,
      sortable:true
     },

     {
        name:'Branch',
        selector:row=>row.branch ,
        width: "150px" 
        ,wrap:true ,
        sortable:true
       },

     {
        name:'Date Created',
        selector:row=>row.dat ,
        width: "220px" 
        ,wrap:true ,
        sortable:true
       },

        {
        
       name:'Status' , cell:row=> <div className="form-check form-switch">
       <input class="form-check-input" type="checkbox"  checked={row.save_aproval} />

     </div>,
       width:"70px"
      }  ,


      {
        name:'Report' , cell:row=> <button className='btt'> <BiSolidReport className='preview-btn'/> </button>,
       width:"70px"
      }  ,

     
    

  ]


  const sendMessage=()=>{

        if(approval_by==''){

            ErrorMessage('Please Select Authorization User')

        }else if(whyMessageRequst==''){

            ErrorMessage('Select Why Request')

        }else {

            const aqw =getIndAcc.filter(obj=>obj.account_number==selectedRow.account_number && obj.save_aproval==true && whyMessageRequst=='Opening Account Authorization' )

          
            if(aqw.length>0){
        
                ErrorMessage('Selected Account Already Authorized')
        
            }else{

                const apm=message.filter(obj=>obj.table=='Account Table' && obj.approval_status==false && obj.table_pk==selectedRow.trans_code && obj.message_sum==whyMessageRequst )

                if(apm.length>0){
        
                    ErrorMessage(whyMessageRequst +' Already Sent For Approval')
            
                }else{

            let table='Account Table'
            let level=10
            let action=1
            let sent_by=userInfo.username
            let table_pk=selectedRow.trans_code;
            let branch=userProfileInfo.branch
            let pic=userProfileInfo.image
            let dat=moment().format('YYYY-MM-DD')
            let dat2=moment().format('MMMM Do YYYY, h:mm:ss a')
            let title='Account Edit Request'
            let message_sum=whyMessageRequst
            let ty=selectedRow.acc_type
            let name=selectedRow.account_name
            let accnumber=selectedRow.account_number
     

            dispatch(aprMessageAction(table , action , sent_by ,RequestMessage ,table_pk,pic ,dat,dat2 ,title ,message_sum ,approval_by ,ty ,name ,accnumber))

        }

        }
    }
      

  }

const successMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "success",
      title: message ,
      showConfirmButton: false,
      timer: 10000
    });
  }

  const ErrorMessage=(message) => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: message ,
      showConfirmButton: false,
      timer: 1500000
      
    
    });

  }

  const accountexit=(e)=>{
    e.preventDefault()
    setGetProduct(e.target.value)
    dispatch(CustomerAccountTypeAction(memberid))
  }

  useEffect(()=>{

    try {

        if(AccountTypeDetails){

   
            if(AccountTypeDetails.length >0){
        
                const fineAccount=AccountTypeDetails.find(obj=>obj.product_name==product_name)
        
                console.log(fineAccount)
                
                if(fineAccount){
          
                    ErrorMessage('Customer Already Has a ' + product_name)
        
                   // setGetProduct("Select")
                    //setProductCode("")
                    setInitial("")
                    setaccount_number("")
                    
        
                }else{
        
                    loadCodeIn()
        
                }
        
        
            }else {
        
                loadCodeIn()
        
            }
        }else {
            loadCodeIn()
        }
        
    } catch (error) {
        
    }

   


  },[CustomerAccountTypeDetailsReducer])

  useEffect(()=>{

    try {
        setgetIndAcc(customerAccounts.filter(obj=>obj.acc_type=='Individual Account'))
        
    } catch (error) {
        
    }

   

  },[CustomerAccountReducer])


  useEffect(()=>{

    if(creataccout){

      successMessage('Customer Account Loogged For Authorization')
     
      dispatch(loadCustomerAccountAction())

     //setaccountcode(accountInfo.trans_code)
     //console.log(accountInfo.trans_code)

    //Send Account Apropval

    let table = 'Account Table'
    let level = 10
    let action = 1
    let sent_by = userInfo.username
    let table_pk = accountInfo.trans_code;
    let branch = userProfileInfo.branch
    let pic = userProfileInfo.image
    let dat = moment().format('YYYY-MM-DD')
    let dat2 = moment().format('MMMM Do YYYY, h:mm:ss a')
    let title = 'Account Edit Request'
    let message_sum = 'Request For Account Opening Authorization'
    let ty = accountInfo.acc_type
    let name = accountInfo.account_name
    let accnumber = accountInfo.account_number
    let RequestMessage = "Requesting For Your Authorization or Approval Of This Account"


    dispatch(aprMessageAction(table, action, sent_by, RequestMessage, table_pk, pic, dat, dat2, title, message_sum, approval_byb, ty, name, accnumber, '-', '-', '-'))


     dispatch({type:MEMBER_RESET_DETAILS})
     dispatch({type:CREATE_ACCOUNT_RESET})

     playOn();

      clear();

    }

    if(coraccsufferror){

      ErrorMessage('Network Error.Please Re-Save')
    
    }

  },[createBankAccountReducer])



  useEffect(()=>{
    
    setprouser(AllUser.filter(obj => (obj.loginstatus == 'System Administrator' || obj.loginstatus == 'Branch Manager' || obj.loginstatus == 'Operations Manager'
        || obj.loginstatus == 'General Manager' || obj.loginstatus == 'Bank Administrator') && obj.username != userInfo.username))

  } ,[dispatch])
  
  

  const loadCodeIn=()=>{
    
    try {

        const selectedProduct=products.find(obj=>obj.product_name==product_name)

        //console.log(selectedProduct)
    
        setProductCode(selectedProduct.product_code)
        setInitial(selectedProduct.idr_ind)
    
        const getBranchCode=branchsInfo.find(obj=>obj.name==branch)
    
        setaccount_number(bankInfo.code + getBranchCode.branch_code+selectedProduct.product_code+code)
        setbank_code(bankInfo.code)
        setbranch_code(getBranchCode.branch_code)
        setcustomer_number(selectedProduct.product_code+code)
        
    } catch (error) {
        
    }
   

  }

  const createBankAccount=()=>{

    if(MemberDetailsInfo.save_aproval){

        if(bill_dat=='' || bill_address=='' || bill_details=='' || bill_tel=='' || rname1=='' || rtel1=='' || rrel1=='' || raddress1=='' || rname2=='' || rtel2=='' || rrel2=='' 
            || raddress2=='' || product_name=='' || product_code=='' || account_number=='' || branch=='' || signature=='' ||  initial=='' ){
    
            ErrorMessage('Please All Required Fields Must Be Fill Out')
            
        }else if(!(bill_tel.match('^[0-9]{10}$'))){
    
            ErrorMessage('Please provide a valid Bill Telephone Number')
    
        }else if(!(rtel1.match('^[0-9]{10}$'))){
    
            ErrorMessage('Please provide a valid 1st Reference Telephone Number')
    
        }else if(!(rtel2.match('^[0-9]{10}$'))){
    
            ErrorMessage('Please provide a valid 2nd Reference Telephone Number')

        }else if(approval_byb==''){
    
            ErrorMessage('Please Select Request Account Approval User')
    
        }else {
    
            
            let level=userProfileInfo.Authorisation_Level
            let save_by=userInfo.username
            let acc_type='Individual Account'
            let customer_id_id=memberid
            let dat=moment().format('MMMM Do YYYY, h:mm:ss a')
            let sms_number=tel
    
            dispatch(updateMemberInfoAccount({trans_code:memberid ,
                bill_dat ,
                bill_address ,
                bill_details ,
                bill_tel ,
                
                rname1,
                rtel1,
                rrel1,
                raddress1,
    
                rname2,
                rtel2,
                rrel2,
                raddress2
            }))
    
    
           dispatch(createCustomerBankAccounAction(customer_id_id ,product_name ,product_code , account_number,account_name ,branch ,dat,initial ,signature,level 
            ,save_by ,acc_type ,bank_code ,branch_code ,customer_number,'','' ,'' ,'' ,'',sms_number ,card_ty ,card_Id ,'','' ,'' ,'',account_name ,tel ,'','' ,'' ,''))
    
            

            dispatch({type:CUSTOMER_ACCOUNTS_TYPE_RESET_DETAILS})

            
             
          
        }

    }

  }
  

  useEffect(()=>{

    dispatch(loadCustomerAccountAction())


    if(MemberDetailsInfo){

      if(!MemberDetailsInfo.save_aproval){

       // ErrorMessage('Member Information Pending Authorization')
        
      }else {

        setmemberid(MemberDetailsInfo.trans_code)
        setCode(zeroFill(7,MemberDetailsInfo.trans_code))
        setname(MemberDetailsInfo.name)
        setpic(MemberDetailsInfo.pic)
        SetSex(MemberDetailsInfo.sex)
        setr_add(MemberDetailsInfo.r_add)
        setpob(MemberDetailsInfo.pob)
        setreg(MemberDetailsInfo.reg)
        setnland(MemberDetailsInfo.nland)
        settel(MemberDetailsInfo.tel)
        setemail(MemberDetailsInfo.email)
        setdob(MemberDetailsInfo.dob)
        setcity(MemberDetailsInfo.city)
        setedu(MemberDetailsInfo.edu)
        setmar(MemberDetailsInfo.mar)
        setcard_Id(MemberDetailsInfo.card_Id)


        setincome_s(MemberDetailsInfo.income_s)
        Setb_long(MemberDetailsInfo.b_long)
        setbus(MemberDetailsInfo.bus)
        setb_reg(MemberDetailsInfo.b_reg)
        setb_reg_no(MemberDetailsInfo.b_reg_no)
        setdat_r(MemberDetailsInfo.dat_r)
        setdat_in(MemberDetailsInfo.dat_in)
        setnat_b(MemberDetailsInfo.nat_b)
        setfounder(MemberDetailsInfo.founder)
        setfound_con(MemberDetailsInfo.found_con)
        setbus_loc(MemberDetailsInfo.bus_loc)

        setnk1(MemberDetailsInfo.nk1)
        setnkc1(MemberDetailsInfo.nkc1)
        setnkr1(MemberDetailsInfo.nkr1)
        setnkb1(MemberDetailsInfo.nkb1)

        setnk2(MemberDetailsInfo.nk2)
        setnkc2(MemberDetailsInfo.nkc2)
        setnkr2(MemberDetailsInfo.nkr2)
        setnkb2(MemberDetailsInfo.nkb2)

        setcard_ty(MemberDetailsInfo.card_ty)
        setcard_is_dat(MemberDetailsInfo.card_is_dat)
        setcard_ex_date(MemberDetailsInfo.card_ex_date)

        setother_info(MemberDetailsInfo.other_info)
        setbill_dat(MemberDetailsInfo.bill_dat)
        setbill_address(MemberDetailsInfo.bill_address)
        setbill_details(MemberDetailsInfo.bill_details)
        setbill_tel(MemberDetailsInfo.bill_tel)
        setrname1(MemberDetailsInfo.rname1)
        setrtel1(MemberDetailsInfo.rtel1)
        setrrel1(MemberDetailsInfo.rrel1)
        setraddress1(MemberDetailsInfo.raddress1)

        setrname2(MemberDetailsInfo.rname2)
        setrtel2(MemberDetailsInfo.rtel2)
        setrrel2(MemberDetailsInfo.rrel2)
        setraddress2(MemberDetailsInfo.raddress2)

      }

    }  
  
 
  },[MembereDetailsReducer])


  useEffect(()=>{

    dispatch(messageListAction(userInfo.username))

    setprousers(AllUser.filter(obj=>(obj.loginstatus=='System Administrator' || obj.loginstatus=='Branch Manager' || obj.loginstatus=='Operations Manager' 
        || obj.loginstatus=='General Manager' )&& obj.username!=userInfo.username))

       
  },[dispatch])


  useEffect(()=>{

    if(message){

        settotalUserMessages(message.length)
    }
  },[apro_Message_Reducer])

 
 


  
  const clear=()=>{
    
    setmemberid("")
    setCode("")
    setname("")
    setpic("")
    SetSex("")
    setr_add("")
    setpob("")
    setreg("")
    setnland("")
    settel("")
    setemail("")
    setdob("")
    setcity("")
    setedu("")
    setmar("")
    setcard_Id("")


    setincome_s("")
    Setb_long("")
    setbus("")
    setb_reg("")
    setb_reg_no("")
    setdat_r("")
    setdat_in("")
    setnat_b("")
    setfounder("")
    setfound_con("")
    setbus_loc("")

    setnk1("")
    setnkc1("")
    setnkr1("")
    setnkb1("")

    setnk2("")
    setnkc2("")
    setnkr2("")
    setnkb2("")

    setcard_ty("")
    setcard_is_dat("")
    setcard_ex_date("")

    setother_info("")
    setbill_dat("")
    setbill_address("")
    setbill_details("")
    setbill_tel("")
    setrname1("")
    setrtel1("")
    setrrel1("")
    setraddress1("")

    setrname2("")
    setrtel2("")
    setrrel2("")
    setraddress2("")
   
    setSignature("")
    setGetProduct("")
    setProductCode("")
    setInitial("")
    setaccount_number("")
    setImages("")
    
  }

  const [search ,setsearch]=useState('')


  const searchMember =(e)=>{

    setsearch(e.target.value)

    const newData =getIndAcc.filter(row=>{

       
  return row.account_number.toLowerCase().match(e.target.value.toLowerCase())
        
    })
    setgetIndAccDate(newData)
  }

  const account_report=()=>{

    let acc_type='Individual Account'

    window.open(`http://localhost/report/Account_type.html?acc_type=${acc_type}`, "_blank");

   }


  return (
    <div>

        
<h3 >Individual Account Opening Information</h3>
<hr className='mb-4'/>

{memberDetailsLoading && <Loader/>}
{customerAccountUpdateLoading && <Loader/>}






<div className='card'>
        <div className=''>
       
      <Tabs
      defaultActiveKey="New Account"
      id="justify-tab-example"
      className="mb-3"
      justify
    >


      <Tab eventKey="New Account" title="New Account">
      <h4 className="card-title ms-5 mb-4"> Account Opening Form</h4>
      <div className='solcon overflow-auto vh-100'> 

      <div className="tab-pane fade show active profile-overview ms-5 me-5" id="profile-overview">
             
              
        <Stepper
                                strokeColor="#00000"
                                fillStroke="#012970"
                                activeColor="#06A3DA"
                                activeProgressBorder="2px solid #06A3DA"
                                defaultActiveStep='true'

                                submitBtn={<button className="btn-class page1">Save</button>}
                                continueBtn={<button className="show_info_btn page1">Next Page</button>}
                                backBtn={<button className="show_info_btn">Previous Page</button>}
                                onSubmit={

                                    (step) => createBankAccount()

                                }
                                >
                            
                                <div className="stepperSubDiv">

                                <Link to="/SearchMemberInfomation"><button className='btn-class py-2 px-4 mt-2 mb-3' type="button"><CiSearch size={25} />Search Member Information</button></Link>

                                    <h3>1.Personal Details</h3>
                                    
                                    <div className="row mt-4">

                                    <div className="col-md-9 col-lg-9">
                                    <label className='lable' for='Surname'>Full Name </label>
                                    <input name="Name" required type="text"  disabled className="form-control" value={account_name} onChange={(e)=>setname(e.target.value)}  />
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <lable className='lable' for='Login Status'>Gender</lable>
                                        <select required disabled  className='form-control' value={sex} >
                                            <option disabled selected value="" >Select</option>
                                            <option value="Male">Male</option>
                                            <option value="Famale">Famale</option>
                                        </select>
                                    </div>

                            
                                    </div>



                                    <div className="row mt-4">
                                
                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Residential Address</label>
                                    <input name="Name" disabled required type="text" className="form-control" value={r_add} 
                                        onChange={(e)=>setr_add(e.target.value)} />
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label  className='lable' for='Surname'>Place Of Birth</label>
                                    <input name="Name" disabled required type="text" className="form-control" value={pob}  
                                        onChange={(e)=>setpob(e.target.value)}/>
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Region</label>
                                    <input name="Name" disabled required type="text" className="form-control" value={reg} 
                                        onChange={(e)=>setreg(e.target.value)}/>
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Nearest Landmark</label>
                                    <input name="Name" disabled  required  type="text" className="form-control" value={nland} 
                                        onChange={(e)=>setnland(e.target.value)} />
                                    </div>


                                    </div>

                                

                                    <div className="row mt-4">

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Telephone Number</label>
                                    <input name="number" disabled required type="number" className="form-control"  value={tel} 
                                        onChange={(e)=>settel(e.target.value)}/>
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label for='Surname'>Email</label>
                                    <input name="email" disabled type="email" className="form-control" value={email} 
                                        onChange={(e)=>setemail(e.target.value)} />
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Date Of Birth</label>
                                    <input name="number" disabled type="date" className="form-control" value={dob} 
                                        onChange={(e)=>setdob(e.target.value)} />
                                    </div>
                                
                                
                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>City</label>
                                    <input name="Place"  disabled type="text" className="form-control" value={city} 
                                        onChange={(e)=>setcity(e.target.value)}/>
                                    </div>
                                    
                                    </div>

                                    <div className="row mt-4 ">

                                    <div className="col-md-3 col-lg-3">
                                    <lable className='lable' for='Login Status'>Type Of Identification</lable>
                                            <select required disabled  className='form-control' value={card_ty}  onChange={(e)=>setcard_ty(e.target.value)}  >
                                                <option selected value="Select" >Select</option>
                                                <option value="Voters Card">Voters Card</option>
                                                <option value="Ghana Card">Ghana Card</option>
                                                <option value="Drivers License">Drivers License</option>
                                                <option value="Passport">Passport</option>
                                                <option value="SSNIT Card">SSNIT Card</option>
                                            </select>
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='ID Number'>ID N0.</label>
                                        <input name="Name" disabled required type="text" className="form-control" value={card_Id}
                                        onChange={(e)=>setcard_Id(e.target.value)} />
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                        <label className='lable' for='Id Issue Date'>Id Issue Date</label>
                                        <input name="Name" disabled required type="date" className="form-control" value={card_is_dat}  
                                        onChange={(e)=>setcard_is_dat(e.target.value)} />
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                        <label className='lable' for='Id Expiry Date'>Id Expiry Date</label>
                                        <input name="Name" disabled required type="date" className="form-control" value={card_ex_date} 
                                        onChange={(e)=>card_ex_date(e.target.value)} />
                                    </div>


                                    </div>
                                
                                    <div className="row mt-4">


                                    <div className="col-md-3 col-lg-3">
                                    <lable className='lable' for='Login Status'>Educational Status</lable>
                                            <select required disabled className='form-control' value={edu} 
                                            onChange={(e)=>setedu(e.target.value)} >
                                                <option disabled selected value="" >Select</option>
                                                <option value="Tertiary">Tertiary</option>
                                                <option value="Secondary">Secondary</option>
                                                <option value="Others">Others</option>
                                            </select>
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <lable className='lable' for='Login Status'>Marital Status</lable>
                                            <select required disabled className='form-control' value={mar} 
                                            onChange={(e)=>setmar(e.target.value)} >
                                                <option disabled selected value="" >Select</option>
                                                <option value="Married">Married</option>
                                                <option value="Single">Single</option>
                                                <option value="Divorce">Divorce</option>
                                            </select>
                                    </div>
                                    </div>

                                </div>
                            
                                <div className="stepperSubDiv">
                                <h3>2.Economic Activities /Corporate Details</h3>

                                <div className="row mt-4">

                                <div className="col-md-3 col-lg-3">
                                    
                                    <lable className='lable' for='Login Status'>Employment Status</lable>
                                            <select required  className='form-control' disabled value={income_s} 
                                            onChange={(e)=>setincome_s(e.target.value)}>
                                                <option disabled selected value="" >Select</option>
                                                <option value="Self Employed">Self Employed</option>
                                                <option value="Employed By A Company">Employed By A Company</option>
                                            </select>
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>If Self Employed How Long?</label>
                                    <input name="Name" type="text" className="form-control" disabled value={b_long} onChange={(e)=>Setb_long(e.target.value)} />
                                    </div>
                                

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Name Of Business</label>
                                    <input name="Name" type="text" className="form-control" disabled value={bus} onChange={(e)=>setbus(e.target.value)}/>
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    
                                    <lable className='lable' for='Login Status'>Is Your Business Registered</lable>
                                            <select required  className='form-control'disabled >
                                                <option disabled selected value="" >Select</option>
                                                <option value="Yes">Yes</option>
                                                <option value="No">No</option>
                                            </select>
                                    </div>

                                    </div>


                                

                                    <div className="row mt-4">

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Business Registration Number</label>
                                    <input name="number" type="text" className="form-control" disabled value={b_reg_no} 
                                        onChange={(e)=>setb_reg_no(e.target.value)} />
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Date Of Registration</label>
                                    <input name="Name" type="date" className="form-control" disabled value={dat_r}
                                        onChange={(e)=>setdat_r(e.target.value)} />
                                    
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Date Of Incorporation</label>
                                    <input name="Name" type="date" className="form-control" disabled value={dat_in}
                                        onChange={(e)=>setdat_in(e.target.value)}/>
                                    
                                    </div>
                                
                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Nature Of Business</label>
                                    <input name="Name" type="text" className="form-control" disabled value={nat_b} 
                                        onChange={(e)=>setnat_b(e.target.value)}/>
                                    
                                    </div>


                                    </div>

                                

                                    <div className="row mt-4">


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Founders Names separate by comma</label>
                                    <input name="Name" type="text" className="form-control" disabled value={founder} 
                                        onChange={(e)=>setfounder(e.target.value)} />
                                    
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Founders Contacts separate by comma</label>
                                    <input name="Name" type="text" className="form-control" disabled value={found_con} 
                                        onChange={(e)=>setfound_con(e.target.value)} />
                                    
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Surname'>Business Location</label>
                                    <input name="Name" type="text" className="form-control" disabled value={bus_loc} 
                                        onChange={(e)=>setbus_loc(e.target.value)} />
                                    
                                    </div>


                                    </div>


                                </div>
                                <div className="stepperSubDiv">
                                <h3>3.Next Of Kin And Beneficiary</h3>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>1st Beneficiary</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label  for="Address" className=" lable col-md-4 col-lg-4 col-form-label"> Full Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control" value={nk1} 
                                                        onChange={(e)=>setnk1(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label  for="Address" className="col-md-4 col-lg-4 col-form-label lable">Contact:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="number" className="form-control" value={nkc1}
                                                        onChange={(e)=>setnkc1(e.target.value )}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label  for="Address" className="col-md-4 col-lg-4 col-form-label lable">Relation:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control" value={nkr1}
                                                        onChange={(e)=>setnkr1(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Benefits %:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control"  value={nkb1} 
                                                        onChange={(e)=>setnkb1(e.target.value)}/>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>



                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>2nd Beneficiary</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label  for="Address" className="col-md-4 col-lg-4 col-form-label lable">Full Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control" value={nk2}
                                                        onChange={(e)=>setnk2(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Contact:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="number" className="form-control" value={nkc2} 
                                                        onChange={(e)=>setnkc2(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Relation:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control" value={nkr2} 
                                                        onChange={(e)=>setnkr2(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable">Benefits %:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" disabled type="text" className="form-control" value={nkb2} 
                                                        onChange={(e)=>setnkb2(e.target.value)} />
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>




                                </div>

                            

                                </div>

                                <div className="stepperSubDiv">
                                <h3>4.Image , Other Infomation And Signature</h3>

                                
                                <div className='row'>

                                <div className='col-md-2'>

                                <strong for="Address" className="col-md-4 col-lg-4 col-form-label">Image</strong>
                                <div className="card mt-2">
                                <div className="embed-responsive embed-responsive-16by9">
                                    {pic !='' ? ( <img alt="Card image cap" className="card-img-top embed-responsive-item"  src={pic} /> ) 
                                    : (<img  alt="Card image cap" className="card-img-top embed-responsive-item" src={profile} />)}
                                </div>
                                </div>

                                    </div>

                                <div className='col-md-5'>

                                <div className="row">
                                        <strong for="Address" className="col-md-4 col-lg-4 col-form-label">Other Information:</strong>
                                        <div className="col-md-10 col-lg-10">
                                            <textarea disabled  className='other_info' rows="8" cols="60" value={other_info} 
                                            onChange={(e)=>setother_info(e.target.value)}>  
                                            
                                            </textarea>  
                                    </div>
                                    </div>


                                </div>
                                
                            
                                </div>
                            
                                </div>


                                <div className="stepperSubDiv">
                                <h3>5. Utility Bill Data</h3>

                                <div className='row mt-4'>
                                
                                <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Bill Date</label>
                                    <input name="Name" type="date" className="form-control" value={bill_dat} onChange={(e)=>setbill_dat(e.target.value)} />
                                    
                                    </div>


                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable'  for='Utility'><samp className='text-danger'>* </samp>Bill Address</label>
                                    <input name="Name" type="text" className="form-control" value={bill_address} onChange={(e)=>setbill_address(e.target.value)}  />
                                    
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Bill Details</label>
                                    <input name="Name" type="text" className="form-control" value={bill_details} onChange={(e)=>setbill_details(e.target.value)}  />
                                    
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                    <label className='lable' for='Utility'><samp className='text-danger'>* </samp>Bill Telephone Number </label>
                                    <input name="Name" type="number" className="form-control" value={bill_tel} onChange={(e)=>setbill_tel(e.target.value)}  />
                                    
                                    </div>

                                </div>
                                
                                
                            
                                </div>



                                <div className="stepperSubDiv">
                                <h3>6.Reference Information</h3>
                                <div className='row mt-4'>
                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>1st Reference</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Full Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  type="text" className="form-control" 
                                                        value={rname1} onChange={(e)=>setrname1(e.target.value)} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Telephone Number:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  type="number" className="form-control" 
                                                        value={rtel1} onChange={(e)=>setrtel1(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Relation:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  type="text" className="form-control" 
                                                        value={rrel1} onChange={(e)=>setrrel1(e.target.value)}  />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Address/Location:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="text" className="form-control" 
                                                        value={raddress1} onChange={(e)=>setraddress1(e.target.value)}/>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>
                                    


                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>2nd Reference</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Full Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  type="text" className="form-control" 
                                                        value={rname2} onChange={(e)=>setrname2(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Telephone Number:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="number" className="form-control"
                                                        value={rtel2} onChange={(e)=>setrtel2(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Relation:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="text" className="form-control" 
                                                        value={rrel2} onChange={(e)=>setrrel2(e.target.value)}  />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Address/Location:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="text" className="form-control" value={raddress2} onChange={(e)=>setraddress2(e.target.value)}/>
                                                    </div>
                                                </div>
                                            
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            

                                </div>

                                <div className="stepperSubDiv">
                                <h3 className='mb-3'>7.Account Details</h3>
                                <div className='row mb-4'>
                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>Product Infomation</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Product Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                    <select required  className='form-control'onChange={accountexit} value={product_name} >
                                                    <option selected disabled value="" >Select</option>
                                                    {products.map((product)=>(
                                                        <option value={product.product_name}>{product.product_name}</option>
                                                    ))} 
                                                        
                                                    </select>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Product Code:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  disabled type="number" className="form-control" value={product_code} 
                                                        onChange={(e)=>setProductCode(e.target.value)}/>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Initial Deposit:</label>
                                                    <div className="col-md-8 col-lg-8" >
                                                        <input name="Name" disabled  type="text" className="form-control" value={initial} />
                                                    </div>
                                                </div>

                                                                            
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className='col-md-6'>
                                        <div className='card'>
                                            <div className='card-header'>Accounts</div>
                                            <div className='card-body'>

                                                <div className="row">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Account Number:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name"  type="number" disabled className="form-control" value={account_number} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Account Name:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="text" disabled className="form-control" value={account_name} />
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <label for="Address" className="col-md-4 col-lg-4 col-form-label lable"><samp className='text-danger'>* </samp>Branch:</label>
                                                    <div className="col-md-8 col-lg-8">
                                                        <input name="Name" type="text"  disabled className="form-control" value={branch}  />
                                                    </div>
                                                </div>

                                                
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div className="mb-3 mt-3 col-md-12 col-lg-12">
                                    
                                    <div className='row'>
                                
                                <div className='col-md-6'>
                                <strong for="Address" className="col-md-4 col-lg-4 col-form-label lable "><samp className='text-danger'>* </samp>Upload Signature</strong>
                                <ImageUploading 
                                multiple
                                value={images}
                                onChange={onChange}
                                maxNumber={maxNumber}
                                dataURLKey="data_url"
                                acceptType={["jpg" ,'PNG']}
                                className='mt-4'
                            >
                                {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps
                                }) => (
                                // write your building UI
                                <div className="upload__image-wrapper">
                                    {
                                        images.length ==1 ? '' :(
                                            <button className='btn-class'
                                            style={isDragging ? { color: "red" } : null}
                                            onClick={onImageUpload}
                                            {...dragProps}
                                            >
                                            Browse for Signature
                                            </button>
                                        )
            
                                    }
                                    
                                    &nbsp;
                                    
                                    {imageList.map((image, index) => (
                                    <div key={index} className="image-item">
                                        <img src={image.data_url} alt="" width="120" />
                                        <div className="image-item__btn-wrapper">
                                        <button className='edit-btn '  onClick={() => onImageUpdate(index)}>  <GrDocumentUpdate/></button>
                                        <button className='del-btn ms-3'  onClick={() => onImageRemove(index)}>  <MdDeleteForever/></button>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                                )}
                            </ImageUploading>


                                <div className='mt-5'>
                                <label className='' for='Utility'><samp className='text-danger'>* </samp>Request Account Approval</label>
                                    <select required className='form-control' value={approval_byb} onChange={(e) => setapproval_byb(e.target.value)}>
                                    <option selected disabled value="" >Select</option>
                                    {prouser.map((users) => (
                                        <option value={users.username}>{users.name} - {users.loginstatus}</option>
                                    ))}

                                </select>
                                </div>
                            
            
                                </div>
            
                                    <div className='col-md-6'>
            
                                    <strong for="Address" className="col-md-4 col-lg-4 col-form-label lable"> Take Finger Print</strong>
                                    
                                    </div>
                                </div>
                                        
                                    </div>

                                </div>

                            
                                </div>    
                                
         </Stepper>  

              


        </div>
        </div>
      </Tab>
     

      <Tab eventKey="Edit Profile" title="Individual Account Information" >

      <div className='solcon overflow-auto vh-100'>
        

        <div className='container'>

        <div className='row'>
        <div className='col-md-6'>
        <div className='form-group '>
                    
        <input value={search} onChange={searchMember} type='text' name='product Name' required className='form-control search__input mt-3 mb-3' placeholder='Search'  />
        </div>
        </div>
    </div>


        <div className='card'>
    
    
        {customerAccountsLoading && <Loader/>}

        
        <DataTable className='rdt_Table' columns={Accountcolumns}  data={getIndAccDate=='' ? getIndAcc : getIndAccDate}   
            selectableRowsHighlight 
            highlightOnHover
            fixedHeader
            pagination 
            paginationPerPage={10}
                //onSelectedRowsChange={getSelectedSubjects}
            selectableRows  
                //selectableRowSelected={getPatientInfo} 
                //</div> onRowClicked={getPatientInfo}
            responsive={true}
        >

     </DataTable>




        </div>

        <button onClick={account_report} className="show_info_btn mt-2 table_buttom_margin "> <TbReportSearch/> Account Report</button>

        </div>
        </div>
                
      </Tab>

    </Tabs>
    
        </div>
      </div>



</div>
  )
}

export default IndividualAcc
